import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbb from './component/navbar/navbb';

import Home from './pages/my home/home.jsx';
import NotFound from './pages/NotFound.js';
import COTTON from './pages/plans/cotonplan/plancotton.jsx';
import Smart from './pages/plans/smartplans/plansmart.jsx'
import Modern from './pages/plans/modernplan/planmodern.jsx'
import Vel  from './pages/plans/Velvetplan/plansvel.jsx'
import Exotic from './pages/plans/Exoticplan/planExotic.jsx'
import Elite from './pages/plans/eliteplan/planelit.jsx'

import Kitchens from './pages/Kitchens/Kitchens.jsx';
import Footer from './component/portofolio/footer.js';
import WhatsAppIcon from './component/portofolio/whats.jsx';
import ComForm from './pages/plans/cotonplan/com_form.jsx';
import ContactForm from './pages/conatctme/contact.jsx';
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import AddressDetails from './pages/aboutus/aboutus.jsx';

function App() {
  const lang = useSelector((state) => state.lang.lang);

  return (
    <>
    
    <Helmet>
        <title>{lang === 'ar' ? 'modernhomes شركه الابيات المواكبة' : 'modernhomes main page'}</title>
        <meta name="description" content={lang === 'ar' ? 'صفحة الاتصال الخاصة بشركه الابيات المواكبة modernhomes,شركة الأبيات المواكبة Modern Homes هي شريكك المثالي في تحويل منزلك إلى مساحة معيشية تعكس ذوقك الراقي. نحن متخصصون في الترميم والتشطيب الاحترافي لكامل منزلك، بما في ذلك دورات المياه والمطابخ والأثاث والديكورات. نقدم حلولاً متكاملة تضمن الراحة والسهولة للعميل، مع الالتزام بتقديم أسعار تنافسية تلبي احتياجاتك. دعنا نحقق لك رؤية المنزل الذي تحلم به، مع ضمان الجودة والراحة في كل خطوة.' : 'Our contact page for inquiries and support,Modern Homes is your ideal partner in transforming your house into a living space that reflects your refined taste. We specialize in the complete renovation and professional finishing of your entire home, including bathrooms, kitchens, furniture, and decor. We offer comprehensive solutions that ensure comfort and ease for our clients, while maintaining competitive prices to meet your needs. Let us bring your dream home vision to life, with a guarantee of quality and comfort every step of the way.'} />
        <meta name="keywords" content={lang === 'ar' ? 'اتصال, دعم, مبيعات, تجديد, تصليح, ترميم, تصميم داخلي, مقاول, تشطيب, مكتب تصاميم, ديزاين, تنظيم, تنفيذ, راحة, تنسيق, اثاث, كنب, غرفة نوم, غرفة العاب, زواج, فن, رسم, ابداع, اجهزة كهربائية, تعطير, باقه البيت المخملي, باقه النخبه, الباقه الفريده, الباقات, باقة قطن, باقه اسمارت, الباقه الذكية, استشاره مجانيه, اعمالنا, خدمات نقدمها, تصميم اسلوب حياة, تنظيم المنزل, تنفيذ مشاريع, تسليم مفتاح, باقات مناسبة للكل, بيوت ذكية, حلول ذكية, ضمانات, توفير الراحة' 
: 'contact, support, sales, renovation, repair, restoration, interior design, contractor, finishing, design office, design, organization, implementation, comfort, coordination, furniture, sofa, bedroom, playroom, wedding, art, drawing, creativity, electrical appliances, scenting, velvet home package, elite package, unique package, packages, cotton package, smart package, modern package, free consultation, our work, services we offer, life style design, home organization, project execution, turnkey delivery, packages suitable for everyone, smart homes, smart solutions, guarantees, providing comfort, clear pricing and static plan, bad module, no specific price (ask for more), client choose the service he needs, client choose the designs from the packages, client choose how many rooms, bathrooms, and kitchens he has in his house, client insert his house dimensions and how many floors by square meter, client insert his contact number or social media or emails, client choose the right time & date for calls or visits'} />
        </Helmet>
      <BrowserRouter>
        <Navbb />
        <Switch>
          <Route component={Home} path="/" exact />
          <Route component={COTTON} path="/plans/cotton Package" exact />
          <Route component={Smart} path="/plans/Smart Package" exact />
          <Route component={Modern} path="/plans/modern Package" exact />
          <Route component={Vel} path="/plans/The Velvet Home Package" exact />
          <Route component={Exotic} path="/plans/Exotic Package" exact />
          <Route component={Elite} path="/plans/Elite Package" exact />
          <Route component={Kitchens} path="/Kitchens"exact/>
          <Route component={ComForm} path="/Company"exact/>
          <Route component={ContactForm} path="/contact-us"exact/>
          AddressDetails
          <Route component={AddressDetails} path="/About-Us"exact/>

          <Route component={NotFound} path="*" exact /> 
        
        </Switch>
        <WhatsAppIcon/>
      <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
